@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
.inter {
	font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
	.inter {
		font-family: 'Inter var', sans-serif;
	}
}

body {
	background-color: #0f172a;
}

@layer components {
	a {
		@apply hover:text-gray-400;
	}
}
